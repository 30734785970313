
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { AppRoute } from '@/router/routes';
import { Component, Vue } from 'vue-property-decorator';
import ChainSettingsCard from '@/components/chain/ChainSettingsCard.vue';
import ChainBotListCard from '@/components/chain/ChainBotListCard.vue';
import { Chain } from 'ignite360-core';
import ChainCreateModal from '@/components/chain/ChainCreateModal.vue';
import ChainAddProjectModal from '@/components/chain/ChainAddProjectModal.vue';

@Component({
  name: 'chain-overview',
  components: { ChainBotListCard, ChainSettingsCard, ChainCreateModal, ChainAddProjectModal },
})
export default class ChainOverview extends Vue {
  isDeleting = false;
  showAddProjectModal = false;

  get chain(): Chain | undefined {
    return this.$store.state.chains.chains.find(
      (chain: Chain) => chain.id === this.$route.params.id,
    );
  }

  async requestDeleteConfirmation() {
    if (!this.chain) return;
    const confirmed = confirm(`Are you sure that you want to delete ${this.chain.name}?`);
    if (confirmed) {
      this.isDeleting = true;
      try {
        await this.$store.dispatch('chains/delete', this.chain.id);
        this.$notify.success('Successfully deleted chain');
        await this.$router.replace({ name: AppRoute.Chains });
      } catch (e) {
        this.$notify.error({
          title: 'Deleting the project failed',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      } finally {
        this.isDeleting = false;
      }
    }
  }
}
