
import BotFeaturesCheckboxGroup from '@/components/bot/BotFeaturesCheckboxGroup.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { ChainSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import ChainForm from '@/components/chain/ChainForm.vue';
import { ChainMixin } from '@/mixins/ChainMixin';

@Component({
  name: 'chain-settings-card',
  components: { ChainForm, BotFeaturesCheckboxGroup },
})
export default class ChainSettingsCard extends mixins(ChainMixin) {
  chainSettings: ChainSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.chainSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateChain({
        id: this.chain.id,
        data: {
          ...this.chainSettings,
          selectedFeatures: Array.from(this.chainSettings.selectedFeatures),
        },
      });
      this.$notify.success('Successfully updated settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('chain', { immediate: true, deep: true })
  private reset() {
    this.chainSettings = this.resetValue();
  }

  private resetValue(): ChainSettings {
    return {
      name: this.chain.name,
      bots: this.chain.bots,
      domain: this.chain.domain,
      selectedFeatures: new Set(this.chain.selectedFeatures),
    };
  }
}
