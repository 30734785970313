
import BotAvatar from '@/components/bot/BotAvatar.vue';
import {Bot, Chain} from 'ignite360-core';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'chain-bot-list-card',
  components: { BotAvatar },
})
export default class ChainBotListCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly chain!: Chain;


  get bots(): Bot[] {
    return this.chain.bots || [];
  }

  // sort by last updated desc
  sortBots = (a: Bot, b: Bot) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt).getTime();

    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterBots = (bot: Bot, filterBy: string) => bot.name.toLowerCase().includes(filterBy);
  getBotKey = (bot: Bot) => bot.id;
}
