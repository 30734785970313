
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import isEqual from 'fast-deep-equal/es6';
import { Bot, BotDomain, Chain, UpdateChain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChainSettings } from '@/types';
import ChainForm from '@/components/chain/ChainForm.vue';
import BotAvatar from '@/components/bot/BotAvatar.vue';

@Component({
  name: 'chain-add-project-modal',
  components: { ChainForm, BotAvatar },
})
export default class ChainAddProjectModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  @Prop({ required: true, type: Object })
  readonly chain!: Chain;

  isSubmitting = false;

  selectedBots: Bot[] = [];

  filterBotsBy = '';

  get bots(): Bot[] {
    return this.$store.state.bots.bots;
  }

  get selectedBotsHaveChanged(): boolean {
    return !isEqual(
      new Set(this.selectedBots.map((bot) => bot.id)),
      new Set(this.chain.bots?.map((bot) => bot.id) || []),
    );
  }

  mounted() {
    this.selectedBots = this.chain.bots?.slice() || [];
  }

  onSelectBot(bot: Bot) {
    const botIndex = this.selectedBots.findIndex((selectedBot) => selectedBot.id === bot.id);
    if (botIndex >= 0) {
      this.selectedBots.splice(botIndex, 1);
    } else {
      this.selectedBots.push(bot);
    }
  }

  isSelected(bot: Bot) {
    return this.selectedBots.some((b: Bot) => b.id === bot.id);
  }

  async onSubmit() {
    const data: UpdateChain = {
      ...this.chain,
      bots: this.selectedBots,
    };
    this.isSubmitting = true;
    try {
      await this.$updateChain({ id: this.chain.id, data });
      await this.$store.dispatch('chains/fetchAll');
      this.$notify.success('Successfully updated chain projects');
      this.$emit('update:show', false);
    } catch (e) {
      this.$notify.error({
        title: 'Adding projects failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  resetValue(): ChainSettings {
    return {
      name: '',
      bots: [],
      domain: BotDomain.Restaurant,
      selectedFeatures: new Set(),
    };
  }

  // @Watch('show')
  // reset() {
  //   this.chainSettings = this.resetValue();
  // }

  // sort by last updated desc
  sortBots = (a: Bot, b: Bot) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt).getTime();

    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterBots = (bot: Bot, filterBy: string) => bot.name.toLowerCase().includes(filterBy);
  getBotKey = (bot: Bot) => bot.id;
}
